import planning from "@/components/newCollecte/planning";
import Panel from "@/components/newCollecte/panel";
import api from "@/api/marches";
import apiCollecte from "@/api/collectes";

export default {
  data() {
    return {
      client: this.$store.state.client,
      datas: [],
      offset: 0,
      newCreneau: {
        panier: [],
        id_client: "",
        id_creneau: "",
      },
      vol:0
    };
  },

  components: {
    planning,
    Panel,
  },
  methods: {
    save_creneau(event) {
      this.newCreneau.id_client = this.$store.state.client.id_client;
      this.newCreneau.id_creneau = event.Creneau.id_creneau;
      this.newCreneau.panier = this.$store.state.collecteCollectibles;
      this.newCreneau.id_marche = this.$store.state.client.id_marche;
      this.newCreneau.id_type_client = this.$store.state.client.id_type_client;
      this.$store.commit('creneauSelected',event.Creneau)
      if (this.$route.name == "planning") {
        apiCollecte.create(this.newCreneau).then((res) => { 
          if (res.data.status == "success") {
            this.$store.commit('tarifCollecte',res.data.Tarif);
            this.$store.commit('gratuitCollecte',res.data.gratuit);
            this.$router.push({
              name: "client",
              id_client: this.$route.params.id_client,
            });
          }
        });
      } else {
        apiCollecte
          .update(this.newCreneau, this.$route.params.id_collecte)
          .then((res) => {
            if (res.data.status == "success") {
              this.$store.commit('tarifCollecte',res.data.Tarif);
              this.$router.push({
                name: "client",
                id_client: this.$route.params.id_client,
              });
            }
          });
      }
    },
    update_creneau(event) {
      console.log("routerr", event);
      //console.log("Alexa", event, this.$store.state);
      this.newCreneau.id_client = this.$store.state.client.id_client;
      this.newCreneau.id_creneau = event.id_creneau;
      this.newCreneau.panier = this.$store.state.collecteCollectibles;
      this.newCreneau.id_marche = this.$store.client.id_marche;
      this.newCreneau.id_type_client = this.$store.id_type_client;

      apiCollecte
        .update(this.newCreneau, this.$route.params.id_collecte)
        .then((res) => {
          if (res.data.status == "success") {
            this.$router.push({
              name: "client",
              id_client: this.$route.params.id_client,
            });
          }
        });
    },
    moreDate(offset) {
      this.offset += offset;
      this.getCreneaux();
    },
    getRange(startDate, endDate, type) {
      let fromDate = this.$moment(startDate);
      let toDate = this.$moment(endDate);
      let diff = toDate.diff(fromDate, type);
      let range = [];
      for (let i = 0; i < diff; i++) {
        range.push({
          date: this.$moment(startDate).add(i, type),
          creneau: false,
        });
      }
      return range;
    },
    async getCreneaux() {
      let creneaux = await api.getAllCreneaux(
        this.$store.state.client.Marche.id_marche,
        this.offset,
        this.$store.state.client.code_postal,
        this.$store.state.client.ville,
        this.$store.state.client.TypeClient.id_type_client,
      );
      creneaux = creneaux.data.creneaux;

      //  récupérer le numéro de semaine courant
      // date début de la semaine courante
      let startDay = this.$moment().add(this.offset * 4, "week");

      startDay = this.$moment(startDay)
        .startOf("week")
        .toDate();
      // date de fin de la semaine courante
      let endDay = this.$moment().add(this.offset * 4, "week");
      endDay = this.$moment(endDay)
        .endOf("week")
        .toDate();

      let week = this.$moment(startDay).isoWeek();

      // variable récupérer les dates de la semaine
      let range;
      let array = [];
      // 1 boucle par semaine
      for (let j = 0; j < 4; j++) {
        range = this.getRange(startDay, endDay, "days");

        array.push({ week: week, dates: range });
        startDay = this.$moment(startDay, "DD-MM-YYYY").add(7, "days");
        week = this.$moment(startDay).isoWeek();
        endDay = this.$moment(endDay, "DD-MM-YYYY").add(7, "days");
      }
      this.datas = array;

      creneaux.forEach((creneau) => {
        this.datas.forEach((week) => {
          week.dates.forEach((date) => {
            if (date.creneau == false) {
              date.creneau = ["AM","PM"];
            }
            console.log(date, "date in foreach");
            if (
              this.$moment(date.date).format("LL") ==
              this.$moment(creneau.date).format("LL")
            ) {
              if (creneau.heure_debut < "12:00:00") {
                date.creneau[0] = creneau
              } else {
                date.creneau[1] = creneau
              }
              // date.creneau.push(creneau);
              console.log(week, "week");
            }
          });
        });
      });

      console.log(this.datas, "datas avec creneau");
    },
    changeVol(vol){
      console.log(vol,'change vol')
      this.vol= vol
    }
  },
  async created() {
    this.getCreneaux();
  },
};